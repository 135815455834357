<template>
  <div class="assistance adjust_width">
    <title_cat>Assistance</title_cat>

    <div class="info">

<!--      <div class="t">Gagnez du temps avec l'interface livreurs</div>-->
      <div class="p">
        Besoin d'aide ? <br>
        Contactez-nous sur <a href="mailto:contact@monresto.click">contact@monresto.click</a>
      </div>

    </div>
    
  </div>
</template>

<script>
export default {
  name: "assistance"
}
</script>

<style scoped>
.info { background: var(--input); padding: 16px 20px; margin-bottom: 26px; margin-top: -16px; }
.info .qrcode { flex: 0 0 auto; width: 300px; height: 300px; margin-top: 6px; }
.info .t { font-size: 15px; padding-bottom: 8px; }
.info .p { font-size: 14px; line-height: 21px; font-weight: 400; }

</style>